<template>
  <div>
    <CRow>
      <CCol col="12" lg="12">
        <CCard :accent-color="$theme.accentColor" v-if="user">
          
        <CCardHeader :color="$theme.backColor" text-color="light">
            <span class="float-left"
              ><h2>{{ username }}</h2></span
            >
            <span class="float-right">User #: {{ userId }}</span></CCardHeader
          >

          <CCardBody>
            <UserDetailsCard :user="user" />

            <CForm novalidate>
              <CCard id="user-settings-card" class="px-0">
                <CCardHeader>
                  <CRow class="justify-content-around">
                    <CCol col="10">
                      <span class="float-left"><h5>User Details</h5></span>
                    </CCol>
                    <CCol col="2">
                      <span v-if="canEdit" v-show="!edit" class="float-right">
                        <CButton
                          color="info"
                          size="sm"
                          variant="outline"
                          @click="edit = !edit"
                        >
                          <CIcon name="cil-pencil" />
                          Edit
                        </CButton></span
                      >
                    </CCol>
                  </CRow>
                </CCardHeader>
                <CCardBody class="px-3">
                  <CRow>
                    <CCol>
                      <CInput
                        type="text"
                        label="First Name"
                        :readonly="!edit"
                        :value.sync="form.user.first"
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CInput
                        type="text"
                        label="Last Name"
                        :readonly="!edit"
                        :value.sync="form.user.last"
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CSelect
                        label="User Role"
                        :readonly="!edit"
                        :diabled="!edit"
                        :value.sync="form.user.role"
                        :options="options"
                        placeholder=""
                      >
                        <template #prepend-content
                          ><CIcon name="cil-user"
                        /></template>
                      </CSelect>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CInput
                        type="email"
                        label="Email"
                        vertical
                        :readonly="true"
                        :value="form.user.email"
                        required
                        was-validated
                      >
                        <template #prepend-content
                          ><CIcon name="cil-envelope-closed"
                        /></template>
                        <template #append-content>
                          <CButton
                            v-show="edit"
                            v-if="!passwordReset"
                            variant="ghost"
                            color="danger"
                            size="sm"
                            class="p-0 px-1"
                            @click="resetPassword"
                          >
                            Reset Password
                          </CButton>
                        </template>
                      </CInput>

                      <span
                        class="text-center mt-0 float-right"
                        style="color:var(--success)"
                        v-show="edit"
                      >
                        <CIcon
                          v-show="passwordReset"
                          color="success"
                          name="cil-check"
                        />
                        {{ resetMessage }}
                      </span>
                    </CCol>
                  </CRow>

                  <CFormGroup
                    wrapperClasses="input-group pt-2"
                    description="ex. (999) 999-9999"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-phone" />
                    </template>
                    <template #label>
                      Phone Number
                    </template>
                    <template #input>
                      <masked-input
                        type="tel"
                        name="phone"
                        class="form-control"
                        v-model="form.user.phone_number"
                        :mask="[
                          '(',
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/
                        ]"
                        :guide="true"
                        :readonly="!edit"
                        placeholderChar="#"
                      />
                    </template>
                  </CFormGroup>
                </CCardBody>
                <CCardFooter>
                  <CRow class="justify-content-center">
                    <CCol> 
                      
                    </CCol>
                    <CCol lg="6">
                       <CButtonGroup class="float-right">
                      <span v-show="edit" class="float-right">
                        <CButton
                          color="secondary"
                          size="sm"
                          variant="outline"
                          @click="handleCancelClick"
                        >
                          <CIcon name="cil-x-circle" />
                          Cancel
                        </CButton></span
                      >
                   
                      <span v-show="edit" >
                        <CButton
                          color="info"
                          size="sm"
                          variant="outline"
                          @click="handleSaveClick"
                        >
                          <CSpinner
                            v-show="submitting"
                            color="success"
                            size="sm"
                          />
                          <CIcon name="cil-save" />
                          Save
                        </CButton></span
                      >
                      </CButtonGroup>
                    </CCol>
                  </CRow>
                </CCardFooter>
              </CCard>
            </CForm>
            <CCard border-color="danger" v-if="isAdmin && canAdmin">
              <CCardHeader color="gradient-info"
                ><span style="color:var(--light)"
                  >Danger Zone</span
                ></CCardHeader
              >

              <CCardBody>
                <CButton v-if="isBlocked"
                  color="info"
                  variant="outline"
                  @click="suspendUserClick(false)"
                >
                  <CIcon name="cil-user-follow" />
                  Unblock User
                </CButton>
                 <CButton v-else
                  color="info"
                  variant="outline"
                  @click="suspendUserClick(true)"
                >
                  <CIcon name="cil-user-unfollow" />
                  Suspend User
                </CButton>
                <CButton
                  color="danger"
                  variant="outline"
                  @click="deleteUserClick"
                >
                  <CIcon name="cil-trash" />
                  Delete User
                </CButton>
                <CVerifySliderPopover
                  text="Swipe Right to Delete User"
                  :show.sync="confirmModal"
                  @validated="confirmDelete"
                />
              </CCardBody>
            </CCard>

            <!--CCard>
            <CCardBody>
              <CDataTable
                striped
                small
                fixed
                :items="visibleData"
                :fields="fields"
              />
            </CCardBody>
          </CCard-->
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="goBack">Back</CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CElementCover
      v-show="submitting"
      :boundaries="[{ sides: ['top', 'left'], query: '#media-body' }]"
      :opacity="0.1"
    >
      <h1 class="d-inline">Please Wait...</h1>
      <CSpinner size="6xl" color="info" />
    </CElementCover>
  </div>
</template>

<style scoped>
.label {
  overflow-wrap: normal;
}
.table-overflow-elipses {
  table-layout: fixed;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
</style>

<script>

import MaskedInput from "vue-text-mask";

import UserDetailsCard from "@/components/users/UserDetailsCard";
import CVerifySliderPopover from "@/components/forms/CVerifySliderPopover";

const defaultIMG =
  "https://poc.aarcomm.io/app/grafana/avatar/fd14f83a37594373470f1c9fdb18ad08";
export default {
  name: "UserView",
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.usersOpened = from.fullPath.includes("users");
    });
  },

  data() {
    return {
      usersOpened: null,
      form: {
        user: undefined // Form Data
      },
      _selectedUser: undefined,
      edit: false,
      passwordReset: false,
      resetMessage: "",
      submitting: false,
      confirmModal: false
    };
  },
  components: {
    MaskedInput,
    UserDetailsCard,
    CVerifySliderPopover
  },
  computed: {
    isAdmin() {
      return this.$auth.isAdmin;
    },
    canAdmin(){
      if(!this._selectedUser){
        return false; 
      }
      if(this.isAdmin && this.$auth.user_id !== this.user.uid){
        return true;
      }
      return false;
    }, 
    canEdit() {
      // If the user is admin OR the user is the user, they can edit
      if (this.$auth.isAdmin) {
        return true;
      } else if (this.$auth.user_id === this.user.uid) return true;
      else return false;
    },
    user() {
      return this.form.user; 
    },
    options() {

      let temp = this.$app.availableRoles.map(item=>{
        return{...item, disabled: !this.edit}
      })
      return temp; 
      return [
        /*
        {
          key: "0",
          label: "Viewer Only",
          value: "viewer",
          disabled: !this.edit
        },
        */
        { key: "1", label: "Operator", value: "viewer", disabled: !this.edit },
        { key: "2", label: "Admin", value: "admin", disabled: !this.edit }
      ];
    },
    userInfo() {
      // Returns a map of user summary info
      return [
        { key: "uid", label: "User ID:", value: this.user.uid },
        { key: "role", label: "User Role:", value: this.role },

        {
          key: "registered",
          label: "Registered: ",
          value: this.getDateTime(this.user.registered)
        }
      ];
    },

    status(){
       return this._selectedUser.status; /// Returns the User's Current Status
    }, 
    isBlocked(){
      let status = this._selectedUser.status; 
      if(status){
          if(status.toLowerCase()==="blocked"){
            return true; 
          }
      }
      return false; 
    },

    userId() {
      return this.$route.params.id;
    },
    defaultImg() {
      return defaultIMG;
    },
    userImage() {
      if (this.user) 
        return this.user.img;
      /*
      else if (this.user.picture) return this.user.picture;
      */ else
        return defaultIMG;
    },
    userEmail() {
      return this.userData.filter(param => param.key === "email")[0].value;
    },
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" }
      ];
    },
    role() {
      let item = this.user;
      // TODO: Create a Library function to extract the role "Nickname"
      if (!item || !item.role) return "viewer";
      let role = item.role.toLowerCase(); // Force to lower case
      if (item.role == "admin") {
        return "Administrator";
      } else if (item.role === "user" || item.role === "viewer") {
        return "Operator";
      } else {
        return "View Only";
      }
    },
    userData() {
      const id = this.$route.params.id;

      this._selectedUser = usersData.find((user, index) => index + 1 == id);

      this.form.user = { ...this._selectedUser }; // Data backing object
      const userDetails = this.user
        ? Object.entries(this.user)
        : [["id", "Not found"]];
      return userDetails.map(([key, value]) => {
        return { key, value };
      });
    },
    visibleData() {
      return this.userData.filter(param => param.key !== "username");
    },
    username() {
      return this.user ? this.user.first : ""; // this.userData.filter(param => param.key === "username")[0].value;
    }
  },
  methods: {
    handleCancelClick(e) {
      this.edit = false;
      this.form.user = null;
      this.initForm(this._selectedUser);
    },
    handleSaveClick(e) {
      // TODO: Save user Settings here
      this.updateUser();
    },
    async updateUser() {
      try {
        this.submitting = true;
        let connection = this.$auth.connection;
        let uid = this._selectedUser.uid;
        let body = { ...this.form.user };
        delete body.email; // Sanitize this parameter
        //body.connection = connection;

        body.orgId = this.$auth.user.organizationId;
        console.log(body);
        let res = await this.$api.updateUser(uid, body);
      } catch (err) {
        console.log(err);
      } finally {
        this.submitting = false;
        this.edit = !this.edit;
      }
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/users" });
    },
    getDateTime(date) {
      return this.$moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    getEmail(item) {
      if (!item || !item.email) return "";

      return `<a href="mailto:${item.email}" target="_blank">${item.email} </a>`;
    },
    getBadgeColor(item) {
      if (!item || !item.status) {
        return "danger";
      }
      switch (item.status.toLowerCase()) {
        case "active":
          return "success";
          break;
        case "inactive":
          return "warning";
          break;
        default:
          return "danger";
      }
    },

    initForm(data) {
      this.form.user = { ...data }; // Data backing object

      this.resetMessage = "";
      this.passwordReset = false;
    },

    resetPassword() {
      let body = {
        client_id: undefined,
        email: this._selectedUser.email,
        connection: this._selectedUser.connection
      };
      console.log(body);

      this.$api
        .resetPassword(body)
        .then(res => {
          if (res.status == 200) {
            console.log(res.data);
            this.passwordReset = true;
            this.resetMessage = "Password Reset Email sent to User";
          }
        })
        .catch(err => {
          console.log(err);
          this.resetMessage =
            "Failed to Reset Password. Contact System Administrator";
          this.passwordReset = false;
        });
    },
    confirmDelete(item) {
      try {
        let user = this._selectedUser;
        this.confirmModal = false;
        this.deleteUser(user);
      } catch (err) {
        console(err);
      } finally {
      }
    },
    async deleteUser(item) {
      try {
        this.submitting = true;
        console.log("Deleting user ", item);
        let res = await this.$api.deleteUser(item.uid);
        if (res.status == 204 || res.status == 200 || res.status == 201) {
          console.log(res);
          // Go Back
          // Pop the user off the cached list and return home
          let index = this.$store.getters.users.indexOf(this._selectedUser);
          this.$store.getters.users.splice(index, 1);
          this.goBack();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitting = false;
      }
    },

    async suspendUser(){
       try {
        this.submitting = true;
        let connection = this.$auth.connection;
        let uid = this._selectedUser.uid;
        let body = {
           "blocked": true,
        }
        body.connection = connection;
        

        body.orgId = this.$auth.user.organizationId;
        console.log(uid, body);
        let res = await this.$api.blockUser(uid, body);
        if(res.status===200 || res.status ==201){
          this._selectedUser.status = "Blocked" // TODO: Get the Status from the response
          this.form.user.status= "Blocked"
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitting = false;
        
      }
    }, 
     async unblockUser(){
       try {
        this.submitting = true;
        let connection = this.$auth.connection;
        let uid = this._selectedUser.uid;
        let body = {
           "blocked": false,
        }
        body.connection = connection;
        

        body.orgId = this.$auth.user.organizationId;
        console.log(uid, body);
        let res = await this.$api.blockUser(uid, body);
        if(res.status===200 || res.status ==201){
          this._selectedUser.status = "Pending"
          this.form.user.status= "Pending"
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitting = false;
        
      }
    }, 

    async deleteUserClick() {
      this.confirmModal = true;
    },
    async suspendUserClick(block) {
      console.log(block)
      if(block){
          this.suspendUser(); 
      }
      else{
        this.unblockUser(); 
      }

    },

    load() {
      const id = parseInt(this.userId);

      console.log(id, this.$store.getters.users);

      this._selectedUser = this.$store.getters.users.find(
        user => user.id === id
      );

      this.initForm(this._selectedUser); // Data backing object

      console.log(this.user);
      //this.user = usersData.find((user, index) => index + 1 == id);
      /*
      const userDetails = this.user
        ? Object.entries(this.user)
        : [["id", "Not found"]];
      return userDetails.map(([key, value]) => {
        return { key, value };
      });
      */
    }
  },
  activated() {
    this.load();
  },
  mounted() {
    this.load();
  }
};
</script>
