var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "slider",
      staticClass: "drag_verify w-100 ",
      style: _vm.dragVerifyStyle,
      on: {
        mousemove: _vm.dragMoving,
        mouseup: _vm.dragFinish,
        touchmove: _vm.dragMoving,
        touchend: _vm.dragFinish
      }
    },
    [
      _c(
        "div",
        {
          ref: "progressBar",
          staticClass: "dv_progress_bar",
          style: _vm.progressBarStyle
        },
        [
          _c("CProgress", {
            staticClass: "w-100 h-100 ",
            style: _vm.progressBarStyle,
            attrs: { value: 100, color: "success", animated: "" }
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "message", staticClass: "dv_text", style: _vm.textStyle },
        [_vm._v(_vm._s(_vm.message))]
      ),
      _c(
        "div",
        {
          ref: "handler",
          staticClass: "dv_handler dv_handler_bg",
          style: _vm.handlerStyle,
          on: { mousedown: _vm.dragStart, touchstart: _vm.dragStart }
        },
        [
          _c("FontAwesomeIcon", {
            style: _vm.handlerIconStyle,
            attrs: { size: "lg", icon: _vm.handlerIconClass }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }