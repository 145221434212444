var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CModal", {
        staticStyle: { "touch-action": "none" },
        attrs: {
          show: _vm.showModal,
          centered: "",
          fade: "",
          closeOnBackdrop: "",
          color: "secondary",
          size: "",
          addContentClasses: [
            "bg-transparent",
            "rounded",
            "justify-content-center"
          ]
        },
        on: {
          "update:show": [
            function($event) {
              _vm.showModal = $event
            },
            _vm.handleModalUpdate
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "body-wrapper",
            fn: function() {
              return [
                _c(
                  "CRow",
                  { staticClass: "justify-content-center" },
                  [
                    _c(
                      "CCol",
                      { staticClass: "text-center" },
                      [
                        _c("CDragVerify", {
                          ref: "swiper",
                          attrs: {
                            width: 0,
                            height: 75,
                            color: "var(--secondary)",
                            "handler-icon-color": "var(--light)",
                            text: _vm.text,
                            "success-text": _vm.successText,
                            background:
                              "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,9,9,1) 49%, rgba(2,0,36,1) 100%)",
                            "progress-bar-bg": "rgba(255,255,255,0)",
                            "completed-bg": "var(--success)",
                            "handler-bg": "var(--info)",
                            "handler-icon": "angle-double-right",
                            "success-icon": "check",
                            circle: true
                          },
                          on: { passcallback: _vm.handleSwipe }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "header-wrapper",
            fn: function() {
              return [_c("div", { staticClass: "bg-transparent" })]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [_c("div")]
            },
            proxy: true
          },
          {
            key: "footer-wrapper",
            fn: function() {
              return [_c("div")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }