<template>
<div>
    <CModal
      :show.sync="showModal"
      centered
      fade
      closeOnBackdrop
      color="secondary"
      size=""
      style="touch-action: none"
      @update:show="handleModalUpdate"
      :addContentClasses="[
        'bg-transparent',
        'rounded',
        'justify-content-center',
        
      ]"
    >
      <template #body-wrapper>
        <CRow class="justify-content-center">
          <CCol class="text-center">
            <CDragVerify
              ref="swiper"
              :width="0"
              :height="75"
              color="var(--secondary)"
              handler-icon-color="var(--light)"
              :text="text"
              :success-text="successText"
              background="linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,9,9,1) 49%, rgba(2,0,36,1) 100%)"
              progress-bar-bg="rgba(255,255,255,0)"
              completed-bg="var(--success)"
              handler-bg="var(--info)"
              
              @passcallback="handleSwipe"
              handler-icon="angle-double-right"
              success-icon="check"
              :circle="true"
            >
            </CDragVerify>
          </CCol>
        </CRow>
      </template>
      <template #header-wrapper>
        <div class="bg-transparent" ></div>
      </template>
      <template #footer>
        <div />
      </template>

      <template #footer-wrapper>
        <div />
      </template>
    </CModal>
</div>


</template>

<script>
import CDragVerify from "../base/CDragVerify"
/** Simple Component that Creates a Pop-up Slider using
 * CDragVerify 
 * TODO: Open more Props for user configuration settings. 
 fa fa- Note: We should pre-fix the Icons with fa fa-
 */

export default {
    name: "CVerifySliderPopover",
    props:{
        text: {
            type: String, 
            default: "Swipe Right"
        },
        successText: {
            type: String, 
            default: "Success"
        }, 
        show:{
            type: Boolean
        }
    }, 
    components:{
        CDragVerify
    },
    data(){
        return {
         _showModal: false, 
        }
    }, 
    computed:{
        showModal:{
            get(){ 
                if(this.show && this._showModal){
                    return true; 
                }
                else if (this.show){
                    this._showModal = this.show; 
                }
                
                return this._showModal; 
                
                },
            set(val){
                //this.show = val; 
                this._showModal =val; 
            }
        }
    }, 
    methods:{
      reset(){
        this._showModal = false; 
        this.$refs.swiper.init(); 
      },
       handleSwipe(arg){
          
           this.$emit("validated", arg, this); 
            
           this.reset();  
       }, 
       handleModalUpdate(val, event){
           
           this._showModal = false;  
           this.$emit("update:show", val, this);
       },
    }
}
</script>