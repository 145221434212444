var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { col: "12", lg: "12" } },
            [
              _vm.user
                ? _c(
                    "CCard",
                    { attrs: { "accent-color": _vm.$theme.accentColor } },
                    [
                      _c(
                        "CCardHeader",
                        {
                          attrs: {
                            color: _vm.$theme.backColor,
                            "text-color": "light"
                          }
                        },
                        [
                          _c("span", { staticClass: "float-left" }, [
                            _c("h2", [_vm._v(_vm._s(_vm.username))])
                          ]),
                          _c("span", { staticClass: "float-right" }, [
                            _vm._v("User #: " + _vm._s(_vm.userId))
                          ])
                        ]
                      ),
                      _c(
                        "CCardBody",
                        [
                          _c("UserDetailsCard", { attrs: { user: _vm.user } }),
                          _c(
                            "CForm",
                            { attrs: { novalidate: "" } },
                            [
                              _c(
                                "CCard",
                                {
                                  staticClass: "px-0",
                                  attrs: { id: "user-settings-card" }
                                },
                                [
                                  _c(
                                    "CCardHeader",
                                    [
                                      _c(
                                        "CRow",
                                        {
                                          staticClass: "justify-content-around"
                                        },
                                        [
                                          _c("CCol", { attrs: { col: "10" } }, [
                                            _c(
                                              "span",
                                              { staticClass: "float-left" },
                                              [
                                                _c("h5", [
                                                  _vm._v("User Details")
                                                ])
                                              ]
                                            )
                                          ]),
                                          _c("CCol", { attrs: { col: "2" } }, [
                                            _vm.canEdit
                                              ? _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.edit,
                                                        expression: "!edit"
                                                      }
                                                    ],
                                                    staticClass: "float-right"
                                                  },
                                                  [
                                                    _c(
                                                      "CButton",
                                                      {
                                                        attrs: {
                                                          color: "info",
                                                          size: "sm",
                                                          variant: "outline"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.edit = !_vm.edit
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("CIcon", {
                                                          attrs: {
                                                            name: "cil-pencil"
                                                          }
                                                        }),
                                                        _vm._v(" Edit ")
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCardBody",
                                    { staticClass: "px-3" },
                                    [
                                      _c(
                                        "CRow",
                                        [
                                          _c(
                                            "CCol",
                                            [
                                              _c("CInput", {
                                                attrs: {
                                                  type: "text",
                                                  label: "First Name",
                                                  readonly: !_vm.edit,
                                                  value: _vm.form.user.first
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.form.user,
                                                      "first",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "CRow",
                                        [
                                          _c(
                                            "CCol",
                                            [
                                              _c("CInput", {
                                                attrs: {
                                                  type: "text",
                                                  label: "Last Name",
                                                  readonly: !_vm.edit,
                                                  value: _vm.form.user.last
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.form.user,
                                                      "last",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "CRow",
                                        [
                                          _c(
                                            "CCol",
                                            [
                                              _c("CSelect", {
                                                attrs: {
                                                  label: "User Role",
                                                  readonly: !_vm.edit,
                                                  diabled: !_vm.edit,
                                                  value: _vm.form.user.role,
                                                  options: _vm.options,
                                                  placeholder: ""
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.form.user,
                                                      "role",
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "prepend-content",
                                                      fn: function() {
                                                        return [
                                                          _c("CIcon", {
                                                            attrs: {
                                                              name: "cil-user"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3945887885
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "CRow",
                                        [
                                          _c(
                                            "CCol",
                                            [
                                              _c("CInput", {
                                                attrs: {
                                                  type: "email",
                                                  label: "Email",
                                                  vertical: "",
                                                  readonly: true,
                                                  value: _vm.form.user.email,
                                                  required: "",
                                                  "was-validated": ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "prepend-content",
                                                      fn: function() {
                                                        return [
                                                          _c("CIcon", {
                                                            attrs: {
                                                              name:
                                                                "cil-envelope-closed"
                                                            }
                                                          })
                                                        ]
                                                      },
                                                      proxy: true
                                                    },
                                                    {
                                                      key: "append-content",
                                                      fn: function() {
                                                        return [
                                                          !_vm.passwordReset
                                                            ? _c(
                                                                "CButton",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.edit,
                                                                      expression:
                                                                        "edit"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "p-0 px-1",
                                                                  attrs: {
                                                                    variant:
                                                                      "ghost",
                                                                    color:
                                                                      "danger",
                                                                    size: "sm"
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.resetPassword
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Reset Password "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1277452289
                                                )
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.edit,
                                                      expression: "edit"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text-center mt-0 float-right",
                                                  staticStyle: {
                                                    color: "var(--success)"
                                                  }
                                                },
                                                [
                                                  _c("CIcon", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.passwordReset,
                                                        expression:
                                                          "passwordReset"
                                                      }
                                                    ],
                                                    attrs: {
                                                      color: "success",
                                                      name: "cil-check"
                                                    }
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.resetMessage) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("CFormGroup", {
                                        attrs: {
                                          wrapperClasses: "input-group pt-2",
                                          description: "ex. (999) 999-9999"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prepend-content",
                                              fn: function() {
                                                return [
                                                  _c("CIcon", {
                                                    attrs: { name: "cil-phone" }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _vm._v(" Phone Number ")
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "input",
                                              fn: function() {
                                                return [
                                                  _c("masked-input", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "tel",
                                                      name: "phone",
                                                      mask: [
                                                        "(",
                                                        /[1-9]/,
                                                        /\d/,
                                                        /\d/,
                                                        ")",
                                                        " ",
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        "-",
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/
                                                      ],
                                                      guide: true,
                                                      readonly: !_vm.edit,
                                                      placeholderChar: "#"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.user
                                                          .phone_number,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.form.user,
                                                          "phone_number",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.user.phone_number"
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2879696607
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCardFooter",
                                    [
                                      _c(
                                        "CRow",
                                        {
                                          staticClass: "justify-content-center"
                                        },
                                        [
                                          _c("CCol"),
                                          _c(
                                            "CCol",
                                            { attrs: { lg: "6" } },
                                            [
                                              _c(
                                                "CButtonGroup",
                                                { staticClass: "float-right" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.edit,
                                                          expression: "edit"
                                                        }
                                                      ],
                                                      staticClass: "float-right"
                                                    },
                                                    [
                                                      _c(
                                                        "CButton",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            size: "sm",
                                                            variant: "outline"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleCancelClick
                                                          }
                                                        },
                                                        [
                                                          _c("CIcon", {
                                                            attrs: {
                                                              name:
                                                                "cil-x-circle"
                                                            }
                                                          }),
                                                          _vm._v(" Cancel ")
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.edit,
                                                          expression: "edit"
                                                        }
                                                      ]
                                                    },
                                                    [
                                                      _c(
                                                        "CButton",
                                                        {
                                                          attrs: {
                                                            color: "info",
                                                            size: "sm",
                                                            variant: "outline"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleSaveClick
                                                          }
                                                        },
                                                        [
                                                          _c("CSpinner", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.submitting,
                                                                expression:
                                                                  "submitting"
                                                              }
                                                            ],
                                                            attrs: {
                                                              color: "success",
                                                              size: "sm"
                                                            }
                                                          }),
                                                          _c("CIcon", {
                                                            attrs: {
                                                              name: "cil-save"
                                                            }
                                                          }),
                                                          _vm._v(" Save ")
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.isAdmin && _vm.canAdmin
                            ? _c(
                                "CCard",
                                { attrs: { "border-color": "danger" } },
                                [
                                  _c(
                                    "CCardHeader",
                                    { attrs: { color: "gradient-info" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { color: "var(--light)" }
                                        },
                                        [_vm._v("Danger Zone")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "CCardBody",
                                    [
                                      _vm.isBlocked
                                        ? _c(
                                            "CButton",
                                            {
                                              attrs: {
                                                color: "info",
                                                variant: "outline"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.suspendUserClick(
                                                    false
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("CIcon", {
                                                attrs: {
                                                  name: "cil-user-follow"
                                                }
                                              }),
                                              _vm._v(" Unblock User ")
                                            ],
                                            1
                                          )
                                        : _c(
                                            "CButton",
                                            {
                                              attrs: {
                                                color: "info",
                                                variant: "outline"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.suspendUserClick(
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("CIcon", {
                                                attrs: {
                                                  name: "cil-user-unfollow"
                                                }
                                              }),
                                              _vm._v(" Suspend User ")
                                            ],
                                            1
                                          ),
                                      _c(
                                        "CButton",
                                        {
                                          attrs: {
                                            color: "danger",
                                            variant: "outline"
                                          },
                                          on: { click: _vm.deleteUserClick }
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-trash" }
                                          }),
                                          _vm._v(" Delete User ")
                                        ],
                                        1
                                      ),
                                      _c("CVerifySliderPopover", {
                                        attrs: {
                                          text: "Swipe Right to Delete User",
                                          show: _vm.confirmModal
                                        },
                                        on: {
                                          "update:show": function($event) {
                                            _vm.confirmModal = $event
                                          },
                                          validated: _vm.confirmDelete
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "CCardFooter",
                        [
                          _c(
                            "CButton",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.goBack }
                            },
                            [_vm._v("Back")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CElementCover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.submitting,
              expression: "submitting"
            }
          ],
          attrs: {
            boundaries: [{ sides: ["top", "left"], query: "#media-body" }],
            opacity: 0.1
          }
        },
        [
          _c("h1", { staticClass: "d-inline" }, [_vm._v("Please Wait...")]),
          _c("CSpinner", { attrs: { size: "6xl", color: "info" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }